import React from 'react';

import './header.css';

const Header = () => {
  return (
    <div className="header">
      <h1>Calorias de uma refeição</h1>
    </div>
  );
};

export default Header;