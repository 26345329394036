import React, { useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import './listaCardapio.css';


function calculaTotais(lista){
  const calorias_totais = lista.reduce((accum,item) => accum + parseFloat(item.calorias), 0);
  const proteina = lista.reduce((accum,item) => accum + parseFloat(item.composicao.proteina), 0);
  const gordura = lista.reduce((accum,item) => accum + parseFloat(item.composicao.gordura), 0);
  const carboidrato = lista.reduce((accum,item) => accum + parseFloat(item.composicao.carboidrato), 0);

  return {calorias_totais: calorias_totais, proteina, gordura, carboidrato};
}


function ListaCardapio({ lista }){

  const [totais, setTotais] = useState({calorias_totais: 0.0, proteina: 0.0, gordura: 0.0, carboidrato: 0.0})
  const [ listaAlimentos, setListaAlimentos ] = useState([]);

  useEffect(() => {    
    setListaAlimentos(lista);

    const _totaisCalorias = calculaTotais(lista)
    setTotais(_totaisCalorias);

  }, [lista]);

  return (
    <div id={listaAlimentos.nome} className="lista-cardapio-wrapper">
      
      <div className="header-cardapio">
        <h3>Cardápio</h3>
        <h4>{totais.calorias_totais}kcal</h4>
      </div>

      <p>
        <span>Proteína: <b>{totais.proteina.toFixed(2)}g</b></span>
        <span>|</span>
        <span>Carboidrato: <b>{totais.carboidrato.toFixed(2)}g</b></span>
        <span>|</span>
        <span>Gordura: <b>{totais.gordura.toFixed(2)}g</b></span>
      </p>

      <div id="listaCardapio" className="lista-cardapio">

        {listaAlimentos?.map((item, key)=>{
          return (
            <div id={`item-cardapio-${key}`} className="item-cardapio" data-testid="item-cardapio">
              
              <div className="header-item-cardapio">
                <h4>{item.nome}</h4>
                <h5>Calorias: <b>{item.calorias}kcal</b></h5>
              </div>

              <p>
                <span>Proteína: <b>{item.composicao.proteina.toFixed(2)}g</b></span>
                <span>|</span>
                <span>Carboidrato: <b>{item.composicao.carboidrato.toFixed(2)}g</b></span>
                <span>|</span>
                <span>Gordura: <b>{item.composicao.gordura.toFixed(2)}g</b></span>
              </p>

              <ul>
                {item?.alimentos?.map(([keyAlimento, alimento], index)=>{
                  const itemKeyAlimento = `ItemAlimento-${index}-${keyAlimento}`;
                  return (
                    <li id={`item-prato-${itemKeyAlimento}`}>
                      <div>{alimento.porcao}g</div>
                      <div>{keyAlimento}</div>
                    </li>
                  )
                })}                
              </ul>

              <div className="footer-item-cardapio">
                <IconButton size={'small'} aria-label="delete" onClick={()=> console.log('aaa')}>
                  <DeleteIcon />
                </IconButton>
              </div>

            </div>
          )})
        }   
        
      </div>

    </div>
  );
};

export default ListaCardapio;